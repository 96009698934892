/* .wrapper
{
    background:#000001!important;
} */
.brand
{
 margin:0 auto;
 display: block;
 text-align: center;
}
.brand img
{
    width:420px;
}
.topMenu
{
    display: flex;
    flex-direction: column;
    padding:10px 0 0 0;
    position:relative;
}

.menuContent
{
    margin:0 auto;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:20px;
}
#menuLabel
{
    background:url('../../assets/img/navLabel.png') no-repeat;
    width:1200px;
    height:2px;
    margin:0 auto;
}
.whitepaper-btn,.whitepaper-btn:hover
{       
    color:#73737abd;
    font-weight:bold;
    border:none;
    background: none;
    font-size:15px;
    line-height: 19px;
    font-family: 'Fjalla One', sans-serif!important;
    letter-spacing: 1.3px;
    font-weight: normal;
    transition:all 0.3s;
    white-space: nowrap;
    position: relative;
    width:100px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.menu-btn
{
    color:#73737abd;
    font-weight:bold;
    border:none;
    background: none;
    font-size:15px;
    line-height: 19px;
    font-family: 'Fjalla One', sans-serif!important;
    letter-spacing: 1.3px;
    font-weight: normal;
    transition:all 0.3s;
    white-space: nowrap;
    position: relative;
    width:100px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.menu-btn::after
{
    content:'';
    background:url('../../assets/img/transparentHoverMenu.png');
    transition: 0.3s;
    width:100px;
    height:2px;
    position:absolute;
    bottom:-3px;
    right:50%;
    transform: translate(50%); 
}
.menu-btn:hover
{
    color:#bdbdc4;
    text-decoration: none;
    font-family: 'Fjalla One', sans-serif!important;
    letter-spacing: 1.3px;
    font-weight: normal;
    border:none;
}
.menu-btn:hover::after
{

    content:"";
    background:url('../../assets/img/hoverMenu.png');
    width:100px;
    height:2px;
    position:absolute;
    bottom:-3px;
    right:50%;
    transform: translate(50%); 
}
.activeMenuBtn
{
    color:#bdbdc4;
    font-weight:bold;
    border:none;
    background: none;
    font-size:15px;
    line-height: 19px;
    font-family: 'Fjalla One', sans-serif!important;
    letter-spacing: 1.3px;
    font-weight: normal;
    transition:0.3s;
    white-space: nowrap;
    position:relative;
    width:100px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.activeMenuBtn::after
{
    content:"";
    background:url('../../assets/img/activeMenu.png');
    width:101px;
    height:10px;
    position:absolute;
    bottom:-2px;
    right:50%;
    transform: translate(50%);
}
.activeMenuBtn:hover
{
    color: #bdbdbd;
    text-decoration: none;
}

.disconnect-btn
{
    color:rgba(255, 187, 0, 0.836);
}
.disconnect-btn:hover
{
    color:rgb(255, 187, 0);
    background:none
}
a.address
{
    color:rgba(255, 255, 255, 0.527);
    text-decoration: none;
}
.address:hover
{
    color:rgba(255, 255, 255, 0.651);
    text-decoration: none;
}
.addressMenu
{
    position:absolute;
    right:10px;
    top:17px;
}
.logoutBtn
{
    background:none;
    border:none;
    color:rgb(255, 217, 0);
}
.socials
{
    display:flex;
    gap:20px;
    margin:0 auto;
    padding:10px;
}
.socials img
{
    border-radius:7px;
    padding:9px;
    width:35px;
    height: 35px;
    opacity: .45;
}

.disclaimer
{
    color:rgba(255, 255, 255, 0.404);
    text-align: center;
    font-style:italic;
    font-size:16px;
    padding:5px 0 5px 0;
}

.footer-btn
{
    color:#73737abd;
    font-weight:bold;
    border:none;
    background: none;
    font-size:15px;
    line-height: 19px;
    font-family: 'Fjalla One', sans-serif!important;
    letter-spacing: 1.3px;
    font-weight: normal;
    transition:all 0.3s;
    white-space: nowrap;
    position: relative;
    width:100px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.footer-btn:hover
{
    color:#ffffffbd;
    text-decoration: none;
}
.activeFooterBtn
{
    color:#ffffffbd;
    font-family: 'Fjalla One', sans-serif!important;
    width:100px;
    display:flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.3px;
}
.activeFooterBtn:hover
{
    color:#ffffffbd;
    text-decoration: none; 
}
@media only screen and (max-width:1200px)
{
    #menuLabel
    {
        width:100%;

        background:none;
        border-bottom:2px solid rgba(238, 238, 238, 0.1);
    }
}
@media only screen and (max-width:426px)
{
    .brand img
    {
        width:100%;
    }
}
